/* SimpleMDE Theme Dark v0.0.0 https://github.com/xcatliu/simplemde-theme-dark#readme */

@charset "UTF-8";
/* THIS FILE IS COPIED FROM CODEMIRROR.CSS, PLEASE DONOT EDIT IT. */
/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black; }

/* PADDING */
.CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */ }

.CodeMirror pre {
  padding: 0 4px;
  /* Horizontal padding of content */ }

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white;
  /* The little square between H and V scrollbars */ }

/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap; }

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap; }

.CodeMirror-guttermarker {
  color: black; }

.CodeMirror-guttermarker-subtle {
  color: #999; }

/* CURSOR */
.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0; }

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver; }

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7; }

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1; }

.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7; }

@-webkit-keyframes blink {
  0% { }
  50% {
    background-color: transparent; }
  100% { } }

@keyframes blink {
  0% { }
  50% {
    background-color: transparent; }
  100% { } }

/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit; }

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: -20px;
  overflow: hidden; }

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute; }

/* DEFAULT THEME */
.cm-s-default .cm-header {
  color: blue; }

.cm-s-default .cm-quote {
  color: #090; }

.cm-negative {
  color: #d44; }

.cm-positive {
  color: #292; }

.cm-header, .cm-strong {
  font-weight: bold; }

.cm-em {
  font-style: italic; }

.cm-link {
  text-decoration: underline; }

.cm-strikethrough {
  text-decoration: line-through; }

.cm-s-default .cm-keyword {
  color: #708; }

.cm-s-default .cm-atom {
  color: #219; }

.cm-s-default .cm-number {
  color: #164; }

.cm-s-default .cm-def {
  color: #00f; }

.cm-s-default .cm-variable-2 {
  color: #05a; }

.cm-s-default .cm-variable-3 {
  color: #085; }

.cm-s-default .cm-comment {
  color: #a50; }

.cm-s-default .cm-string {
  color: #a11; }

.cm-s-default .cm-string-2 {
  color: #f50; }

.cm-s-default .cm-meta {
  color: #555; }

.cm-s-default .cm-qualifier {
  color: #555; }

.cm-s-default .cm-builtin {
  color: #30a; }

.cm-s-default .cm-bracket {
  color: #997; }

.cm-s-default .cm-tag {
  color: #170; }

.cm-s-default .cm-attribute {
  color: #00c; }

.cm-s-default .cm-hr {
  color: #999; }

.cm-s-default .cm-link {
  color: #00c; }

.cm-s-default .cm-error {
  color: #f00; }

.cm-invalidchar {
  color: #f00; }

.CodeMirror-composing {
  border-bottom: 2px solid; }

/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0f0; }

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #f22; }

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3); }

.CodeMirror-activeline-background {
  background: #e8f2ff; }

/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white; }

.CodeMirror-scroll {
  overflow: scroll !important;
  /* Things will break if this is overridden */
  /* 30px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative; }

.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent; }

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none; }

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll; }

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll; }

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0; }

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0; }

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3; }

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -30px; }

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important; }

.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4; }

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4; }

.CodeMirror-gutter-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
  /* prevents collapsing before first draw */ }

.CodeMirror pre {
  /* Reset some styles that the rest of the page might have set */
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual; }

.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal; }

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  overflow: auto; }

.CodeMirror-code {
  outline: none; }

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  box-sizing: content-box; }

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden; }

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none; }

.CodeMirror-measure pre {
  position: static; }

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3; }

div.CodeMirror-dragcursors {
  visibility: visible; }

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible; }

.CodeMirror-selected {
  background: #d9d9d9; }

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0; }

.CodeMirror-crosshair {
  cursor: crosshair; }

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0; }

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0; }

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0; }

.cm-searching {
  background: #ffa;
  background: rgba(255, 255, 0, 0.4); }

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: .1px; }

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden; } }

/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: ''; }

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none; }

.CodeMirror {
  background-color: #444;
  border: 1px solid #555;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #aaa;
  font-family: inherit;
  font-size: 18px;
  height: auto;
  line-height: 1.5;
  min-height: 300px;
  padding: 14px;
  z-index: 1; }

.CodeMirror-scroll {
  margin-bottom: -32px;
  margin-right: -32px;
  min-height: 300px;
  padding-bottom: 32px; }

.CodeMirror-fullscreen {
  background-color: #444;
  border: 0;
  border-top: 1px solid #555;
  bottom: 0;
  height: auto;
  left: 0;
  position: fixed !important;
  right: 0;
  top: 58px;
  z-index: 9; }

.CodeMirror-sided {
  width: 50% !important; }

.CodeMirror .CodeMirror-placeholder {
  color: #777; }

.CodeMirror-cursor {
  border-left-color: #aaa; }

.CodeMirror-lines {
  padding: 0; }

.CodeMirror pre {
  padding: 0 0; }

.CodeMirror-selected,
.CodeMirror-focused .CodeMirror-selected,
.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background-color: rgba(255, 255, 255, 0.1); }

.CodeMirror-selected,
.CodeMirror-focused .CodeMirror-selected,
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background-color: rgba(255, 255, 255, 0.1); }

.CodeMirror .CodeMirror-code .cm-tag {
  color: #95bf40; }

.CodeMirror .CodeMirror-code .cm-attribute {
  color: #95bf40; }

.CodeMirror .CodeMirror-code .cm-string {
  color: #777; }

.CodeMirror .CodeMirror-code .cm-link {
  color: #ffd500; }

.CodeMirror .CodeMirror-code .cm-url {
  color: #777; }

.CodeMirror .CodeMirror-code .cm-hr {
  color: #777; }

.CodeMirror .CodeMirror-code .cm-formatting-header,
.CodeMirror .CodeMirror-code .cm-formatting-em,
.CodeMirror .CodeMirror-code .cm-formatting-strong,
.CodeMirror .CodeMirror-code .cm-formatting-strikethrough,
.CodeMirror .CodeMirror-code .cm-formatting-code,
.CodeMirror .CodeMirror-code .cm-formatting-code-block {
  color: #777; }

.CodeMirror .CodeMirror-code .cm-header-1,
.CodeMirror .CodeMirror-code .cm-header-2,
.CodeMirror .CodeMirror-code .cm-header-3,
.CodeMirror .CodeMirror-code .cm-header-4,
.CodeMirror .CodeMirror-code .cm-header-5,
.CodeMirror .CodeMirror-code .cm-header-6 {
  line-height: 2; }

.CodeMirror .CodeMirror-code .cm-header-1 {
  font-size: 32px; }

.CodeMirror .CodeMirror-code .cm-header-2 {
  font-size: 26px; }

.CodeMirror .CodeMirror-code .cm-header-3 {
  font-size: 24px; }

.CodeMirror .CodeMirror-code .cm-header-4 {
  font-size: 22px; }

.CodeMirror .CodeMirror-code .cm-header-5 {
  font-size: 20px; }

.CodeMirror .CodeMirror-code .cm-header-6 {
  font-size: 18px; }

.CodeMirror .CodeMirror-code .cm-header,
.CodeMirror .CodeMirror-code .cm-strong {
  font-weight: 600; }

.CodeMirror .CodeMirror-code .cm-comment {
  background-color: rgba(255, 255, 255, 0.05);
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 14px;
  padding: 6px 0 4px; }

.CodeMirror .CodeMirror-code span.CodeMirror-selectedtext.cm-comment {
  background-color: rgba(255, 255, 255, 0.05); }

.CodeMirror .CodeMirror-code .cm-quote {
  color: #777; }

.CodeMirror .CodeMirror-code .cm-strikethrough {
  text-decoration: line-through; }

.editor-preview {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; }

.editor-preview-side {
  border: 0;
  border-left: 1px solid #555;
  border-top: 1px solid #555;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 58px;
  width: 50%;
  z-index: 9; }

.editor-preview,
.editor-preview-side {
  background-color: #444;
  box-sizing: border-box;
  color: #aaa;
  display: none;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.5;
  overflow: auto;
  padding: 0 14px 14px; }
  .editor-preview pre,
  .editor-preview-side pre {
    background-color: rgba(255, 255, 255, 0.05);
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 14px;
    line-height: 1.2;
    margin-top: 14px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 14px; }
    .editor-preview pre code,
    .editor-preview-side pre code {
      background-color: transparent;
      font-size: 14px;
      padding: 0; }

.editor-preview-active,
.editor-preview-active-side {
  display: block; }

.editor-preview table,
.editor-preview-side table {
  background-color: #444;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }
  .editor-preview table caption,
  .editor-preview-side table caption {
    caption-side: bottom;
    color: #777;
    padding: 7px;
    text-align: left; }
  .editor-preview table th,
  .editor-preview table td,
  .editor-preview-side table th,
  .editor-preview-side table td {
    border: 0;
    border-bottom: 1px solid #555;
    padding: 7px;
    text-align: left; }
  .editor-preview table th,
  .editor-preview-side table th {
    background-color: rgba(255, 255, 255, 0.05);
    font-weight: 600; }

.editor-preview audio:not([controls]),
.editor-preview-side audio:not([controls]) {
  display: none;
  height: 0; }

.editor-preview img,
.editor-preview-side img {
  max-width: 100%;
  vertical-align: middle; }

.editor-preview audio,
.editor-preview video,
.editor-preview-side audio,
.editor-preview-side video {
  width: 100%; }

.editor-toolbar {
  background-color: #444;
  border: 1px solid #555;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 18px;
  padding: 0 14px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .editor-toolbar::before, .editor-toolbar::after {
    content: ' ';
    display: block;
    height: 1px; }
  .editor-toolbar::before {
    margin-bottom: 7px; }
  .editor-toolbar::after {
    margin-top: 7px; }
  .editor-toolbar a {
    border: 1px solid transparent;
    border-radius: 3px;
    color: #777 !important;
    cursor: pointer;
    display: inline-block;
    font-size: 80%;
    height: 30px;
    margin: 0;
    text-align: center;
    text-decoration: none !important;
    width: 30px; }
    .editor-toolbar a.active, .editor-toolbar a:hover {
      border-color: #555;
      color: #aaa !important; }
    .editor-toolbar a::before {
      line-height: 30px; }
    .editor-toolbar a.fa-header-x::after {
      bottom: -0.25em;
      font-family: inherit;
      font-size: 80%;
      line-height: 0;
      position: relative;
      vertical-align: baseline; }
    .editor-toolbar a.fa-header-1::after {
      content: "1"; }
    .editor-toolbar a.fa-header-2::after {
      content: "2"; }
    .editor-toolbar a.fa-header-3::after {
      content: "3"; }
    .editor-toolbar a.fa-header-bigger::after {
      content: "▲"; }
    .editor-toolbar a.fa-header-smaller::after {
      content: "▼"; }
  .editor-toolbar i.separator {
    border-right: 1px solid #555;
    color: transparent;
    display: inline-block;
    margin: 0 7px;
    text-indent: -10px;
    width: 0; }
  .editor-toolbar:hover a {
    color: #aaa !important; }

.editor-toolbar.fullscreen {
  background-color: #444;
  border: 0;
  box-sizing: border-box;
  height: 58px;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 14px;
  padding-top: 14px;
  position: fixed;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 9; }
  .editor-toolbar.fullscreen::before, .editor-toolbar.fullscreen::after {
    height: 58px;
    left: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 20px; }

@media only screen and (max-width: 700px) {
  .editor-toolbar a.no-mobile {
    display: none; } }

.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background-color: #444;
  border-color: transparent;
  pointer-events: none;
  text-shadow: inherit; }

.editor-statusbar {
  color: #777;
  font-family: inherit;
  font-size: 80%;
  padding: 7px 14px;
  text-align: right; }

.editor-statusbar span {
  display: inline-block;
  margin-left: 1em; }

.editor-statusbar .lines::before {
  content: 'lines: '; }

.editor-statusbar .words::before {
  content: 'words: '; }

.editor-statusbar .characters::before {
  content: 'characters: '; }

/*# sourceMappingURL=simplemde-theme-dark.css.map */
